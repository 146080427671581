import { graphql, useStaticQuery } from 'gatsby'

export const useTradeItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "4DPXvuDk780qvpQb1r8iMM" } }
      ) {
        data: edges {
          node {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1200)
                }
              }
            }
            sectionModules {
              ... on ContentfulMxModules {
                contentSlots {
                  slotTitle
                  actionText
                  actionUrl
                  description
                  markdown {
                    raw
                  }
                  meta {
                    client
                    icon
                    location
                    position
                    quote
                    videoUrl
                  }
                  photos {
                    title
                    file {
                      url
                    }
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                moduleTitle
                moduleMisc {
                  buttonText
                  popupDescription
                  popupTitle
                }
                images {
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
