import React, { SyntheticEvent, useContext, useState } from 'react'

import 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowRightIcon, DownloadIcon } from '@radix-ui/react-icons'
import tw from 'twin.macro'

import useLeadMutate from 'context/lead/use-mutate'
import fireEvent from 'context/tracking/events'
import {
  getShareASaleClickId,
  TrackingContext,
} from 'context/tracking/provider'
import checkGIF from 'images/submit/check.gif'
import { Button } from 'mx/components'
import { FormContactSource } from 'types/crm'
import { emailIsValid } from 'views/utils'

interface Props {
  buttonText?: string
  contactSource?: FormContactSource
  guideName?: string
  placeholder?: string
  resourceUrl?: string
}

const SubmitMailing = ({
  buttonText,
  contactSource,
  guideName,
  placeholder,
  resourceUrl,
}: Props) => {
  const [email, setEmail] = useState<string>('')
  const [completedUpsertLead, setCompletedUpsertLead] = useState<boolean>(false)
  const { state: utmState } = useContext(TrackingContext)

  const {
    upsertLead,
    loading: loadingUpsertLead,
    error,
  } = useLeadMutate({
    onCompleted: ({ upsertOneLead }) => {
      if (upsertOneLead?.hubspotId) {
        fireEvent({
          type: 'identify',
          userId: upsertOneLead.hubspotId,
          email,
        })
        fireEvent({
          type: 'lead_upserted',
          hubspotId: upsertOneLead.hubspotId,
        })
      }
      setCompletedUpsertLead(true)
      if (resourceUrl && guideName) {
        fireEvent({
          type: 'guide_downloaded',
          guideName: guideName,
          email,
        })
        window.open(resourceUrl)
      }
    },
    onError: (error) => {
      console.error('Email newsletter sign-up error: ', error)
    },
  })

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!loadingUpsertLead) {
      const lead = {
        data: {
          form_contact_source: contactSource,
          shareASaleTrackingCode: getShareASaleClickId(),
        },
        dataTracking: {
          utmCampaign: utmState.utmccn,
          utmContent: utmState.utmcct,
          utmMedium: utmState.utmcmd,
          utmSource: utmState.utmcsr,
          utmTerm: utmState.utmctr,
          gclid: utmState.gclid,
        },
        email,
      }
      await upsertLead({
        variables: {
          create: lead,
          update: {},
          where: {
            email,
          },
        },
      })
    }
  }
  const onChangeProspectEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  return (
    <div tw="flex flex-col md:(flex-row)">
      <div tw="w-full flex flex-col justify-around order-2 md:(order-1 w-11/12 pl-0)">
        <form onSubmit={handleSubmit}>
          <div tw="space-y-6">
            <div tw="flex items-center space-x-5 bg-white w-full shadow-sm border border-gray-300 rounded-sm">
              <FontAwesomeIcon
                icon={['fal', 'envelope']}
                tw="pl-4 py-2 text-base text-gray-500"
              />
              <input
                id="email"
                name="email"
                placeholder={placeholder}
                onChange={(e) => onChangeProspectEmail(e)}
                required
                value={email}
                type="email"
                autoComplete="email"
                tw="bg-transparent text-base block px-0 py-2 w-full border-0 placeholder:(text-base text-gray-500) focus:(border-0 ring-0)"
              />
              {error && (
                <p tw="text-base text-gray-500">
                  Please enter a valid email address
                </p>
              )}
            </div>
            <div tw="items-start">
              <Button
                block={!!resourceUrl}
                loading={loadingUpsertLead}
                kind={resourceUrl ? 'download' : 'primary'}
                iconRight={
                  resourceUrl ? (
                    <DownloadIcon tw="relative top-0.5" />
                  ) : (
                    <ArrowRightIcon tw="relative top-0.5" />
                  )
                }
                size="marketing"
                htmlType="submit"
                disabled={!emailIsValid(email)}
              >
                <span>{buttonText || 'Register'}</span>
              </Button>
              {completedUpsertLead && (
                <div css={resourceUrl && tw`!pl-0`} tw="inline p-4">
                  <img tw="inline w-9" src={checkGIF} alt="Validated email" />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export { SubmitMailing }
