import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import { useTradeItems } from 'content-queries/trade/trade'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import Hero from 'mx/components/hero'
import { SectionContent } from 'mx/components/section-content'
import { CommonQuestions } from 'mx/how-it-works/common-questions'
import Layout from 'mx/layout'
import Newsletter from 'mx/trade-program/newsletter'
import Reviews from 'mx/trade-program/reviews'
import { FormContactSource } from 'types/crm'

import { resourcesUrls } from '../views/utils'

const TradePage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Trade Program',
    })
  }, [])

  const { data: data } = useTradeItems()

  const [
    {
      node: {
        sectionModules: [
          hero,
          benefits,
          premiumCabinetry,
          options,
          designPartner,
          steps,
          makingProject,
          advantage,
          getFeatured,
          reviews,
          newsletter,
          faqs,
          popup,
        ],
      },
    },
  ] = data

  return (
    <Layout>
      <AutomaticPopup
        buttonText={popup?.moduleMisc.buttonText}
        contactSource={FormContactSource.POPUP_TRADE}
        guideName={'Trade Deck'}
        guideSource={resourcesUrls.tradeDeck}
        popupDescription={popup?.moduleMisc.popupDescription}
        popupImage={popup?.images[0]?.localFile.childImageSharp.gatsbyImageData}
        popupTitle={popup?.moduleMisc.popupTitle}
      />
      <Hero data={hero} listStats={benefits.contentSlots} />
      <SectionContent
        data={premiumCabinetry}
        isLink={false}
        listStats={options.contentSlots}
      />
      <SectionContent
        data={designPartner}
        isLink={false}
        listStats={steps.contentSlots}
      />
      <SectionContent
        data={makingProject}
        isLink={false}
        listStats={advantage.contentSlots}
      />
      <SectionContent data={getFeatured} isLink={false} />
      <Reviews data={reviews} />
      <Newsletter data={newsletter} />
      <CommonQuestions questions={faqs.contentSlots} />
    </Layout>
  )
}
export default TradePage

export const Head = () => {
  const { data: tradeItemsData } = useTradeItems()
  const tradeItemsCollection: any[] = tradeItemsData.map((n: any) => ({
    image: n.node.image.localFile.childImageSharp.gatsbyImageData,
  }))
  return (
    <SEO
      description="Explore our Professional Services for tailored trade solutions, expert support, and industry insights to enhance your business"
      path="/trade"
      title="Trade"
      image={tradeItemsCollection[0].image.images.fallback.src}
    />
  )
}
