import React from 'react'

import 'twin.macro'

import { FormContactSource } from 'types/crm'

import { SubmitMailing } from '../locations/submit-mailing'
import { Section } from '../section'

interface NewsletterProps {
  data: {
    contentSlots: {
      actionText?: string
      description?: string
      photos?: {
        title?: string
        file: { url: string }
      }[]
      slotTitle: string
    }[]
  }
}
export const Newsletter = ({ data }: NewsletterProps) => {
  const newsletterData = data.contentSlots[0]
  return (
    <Section tw="mt-44 max-w-full" padding="bottom">
      <div tw="flex flex-col w-full relative bg-gray-100 items-center">
        <div tw="grid grid-cols-6 px-4 py-8 max-w-screen-xl md:(grid-cols-16 mb-0 px-10) 2xl:px-0">
          <div tw="col-span-full h-full border-b border-solid border-gray-200 space-y-4 pb-10 md:(col-start-3 col-end-15)">
            <div tw="flex justify-center w-full mb-10">
              <img
                src={newsletterData.photos?.[0].file.url}
                alt={newsletterData.photos?.[0].title}
              />
            </div>
            <p tw="text-lg max-w-xs font-light text-gray-500 leading-normal mx-auto text-center md:max-w-md">
              {newsletterData.description}
            </p>
            <h2 tw="font-thin leading-tight text-4xl mx-auto text-center text-black">
              {newsletterData.slotTitle}
            </h2>
          </div>
          <div tw="grid col-span-full h-full pt-10 md:(!col-start-6 !col-end-13) lg:(!col-start-7 !col-end-12)">
            <p tw="text-lg max-w-xs font-light text-gray-500 leading-normal mb-3 md:(max-w-md)">
              Company Email *
            </p>
            <SubmitMailing
              contactSource={FormContactSource.SIGNUP_TRADE}
              buttonText={newsletterData.actionText}
              placeholder={'you@example.com'}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Newsletter
